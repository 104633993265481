import { message } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import React from 'react'
import ChangePasswordForm from './change-password.form'

export interface IPasswordModal {
    visible: boolean
    setVisible: (input: boolean) => void
    submitRequest: (i: any) => Promise<any>
    includeExisting: boolean
}

export function ChangePasswordModal({
    visible,
    setVisible,
    submitRequest,
    includeExisting = true,
}: IPasswordModal) {
    const close = () => {
        setVisible(false)
    }
    const submit = async (values: any) => {
        try {
            await submitRequest({
                current_password: values.existingPassword,
                password: values.password,
            })
            message.success('Changed Password!')
        } catch (error) {
            message.error('something went wrong')
        }
        close()
    }

    return (
        <Modal visible={visible} onCancel={close} onOk={close} destroyOnClose footer={[]}>
            <div className="change-password-modal">
                <ChangePasswordForm onSubmit={submit} includeExisting={includeExisting} />
            </div>
        </Modal>
    )
}
