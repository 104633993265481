import { format, toDate } from 'date-fns'
import {
    IReportDataRes,
    ISharingLinkRes,
    ITaxYear,
    ITaxYearDataRes,
    ITaxYearRes,
} from './interfaces'
import {
    API_URL,
    getAuthHeader,
    getAuthHeaderRevisor,
    httpDelete,
    httpDeleteRevisor,
    httpGet,
    httpGetRevisor,
    httpPost,
    httpPostRevisor,
    httpPut,
    httpPutRevisor,
} from '../services/http'
import { Session, User } from '../types/session'
import { getStoredAuthToken, getStoredAuthTokenRevisor } from '../utils/auth-token'
import { APIFunctionTypes, ComplianceReportsTypes } from './enums'
import {
    changeResponse,
    userLocation,
    DocDateSum,
    IAddPlaceAPI,
    IFileListRes,
    ILockRes,
    ISinglePeriodCacheRes,
    ISinglePeriodRes,
    PlaceType,
} from './interfaces'

export abstract class APIFunctions {
    abstract APIType: APIFunctionTypes
    abstract getStoredAuthToken(): string
    abstract getAuthHeader(): HeadersInit
    abstract getLLPeriod(periodId: string): Promise<ISinglePeriodCacheRes>
    abstract getLLPeriodSmall(periodId: string): Promise<ISinglePeriodRes>
    abstract fetchTaxYearData(taxYear: ITaxYear): Promise<ITaxYearDataRes>
    abstract getTaxYears(): Promise<ITaxYearRes>
    abstract lockReport(reportId: string): Promise<ILockRes>
    abstract removeLockFromReport(reportId: string, lockedReportId: string): Promise<boolean>
    abstract getLockedReportUploadURL(reportId: string, lockedReportId: string): string
    abstract getLockedReportUploadFinished(
        reportId: string,
        lockedReportId: string
    ): Promise<boolean>
    abstract getReportData(reportId: string): Promise<IReportDataRes>
    abstract refreshTaxYear(taxYearId: string): Promise<void>
    abstract lockTaxYear(taxYearId: string): Promise<void>
    abstract unlockTaxYear(taxYearId: string): Promise<void>
    abstract shareTaxYear(taxYearId: string): Promise<ISharingLinkRes>
    abstract getDocumentation(LLUserId: string, dateClicked: Date | number | string): Promise<any>
    abstract deleteDocumentation(LLUserId: string, uidToDelete: string): Promise<void>
    abstract getUploadDocPath(LLUserId: string, date: Date | number | string): string
    abstract changeIncludeReportSwitch(
        LLUserId: string,
        date: Date | number | string
    ): Promise<changeResponse>
    abstract getDocDateSum(LLUserId: string, date: Date | number | string): Promise<DocDateSum>
    abstract changeDocIncludeReportSwitch(
        LLUserId: string,
        reportId: string
    ): Promise<changeResponse>
    abstract changeNotesIncludeReportSwitch(
        LLUserId: string,
        reportId: string
    ): Promise<changeResponse>
    abstract changeLocationsIncludeReportSwitch(
        LLUserId: string,
        reportId: string
    ): Promise<changeResponse>
    abstract changeRosterIncludeReportSwitch(
        LLUserId: string,
        reportId: string
    ): Promise<changeResponse>
    abstract insertVacationDay(LLUserId: string, date: Date | number | string): Promise<void>
    abstract deleteVacationDay(LLUserId: string, date: Date | number | string): Promise<void>
    abstract updateUserNote(
        LLUserId: string,
        date: Date | number | string,
        body: Object
    ): Promise<void>
    abstract deleteUserNote(LLUserId: string, date: Date | number | string): Promise<void>
    abstract getPlacePredictions(
        LLUserId: string,
        sessionToken: string,
        request: { input: string },
        callback: (results?: PlaceType[]) => void
    )
    abstract addUserLocation(
        LLUserId: string,
        date: Date | number | string,
        body: IAddPlaceAPI
    ): Promise<void>
    abstract getUserLocationForDate(
        LLUserId: string,
        date: Date | number | string
    ): Promise<userLocation[]>
    abstract deleteUserLocationForDate(
        LLUserId: string,
        date: Date | number | string,
        placeId: string
    ): Promise<void>
    abstract confirmUserLocation(
        LLUserId: string,
        date: Date | number | string,
        placeId: string
    ): Promise<void>
}

export class LLUserFunctions implements APIFunctions {
    APIType: APIFunctionTypes

    constructor() {
        this.APIType = APIFunctionTypes.LLUser
    }

    getStoredAuthToken(): string {
        return getStoredAuthToken()
    }

    getAuthHeader(): HeadersInit {
        return getAuthHeader()
    }

    async getLLPeriod(periodId: string): Promise<ISinglePeriodCacheRes> {
        const res = await httpGet<ISinglePeriodCacheRes>(`/ll-periods/${periodId}`)
        return res.data
    }

    async getLLPeriodSmall(periodId: string): Promise<ISinglePeriodRes> {
        const res = await httpGet<ISinglePeriodRes>(`/ll-periods/${periodId}/small`)
        return res.data
    }

    async fetchTaxYearData(taxYear: ITaxYear): Promise<ITaxYearDataRes> {
        const fetchUrl = `/ll-tax-years/${taxYear.id}`
        const res = await httpGet<ITaxYearDataRes>(fetchUrl)
        return res.data
    }

    async getTaxYears(): Promise<ITaxYearRes> {
        const taxYearsData = await httpGet<ITaxYearRes>('/ll-tax-years/')
        return taxYearsData.data
    }

    async lockReport(reportId: string): Promise<ILockRes> {
        const lockRes = await httpPost<ILockRes>(`/compliance-reports/${reportId}/locked-reports`)
        return lockRes.data
    }

    async removeLockFromReport(reportId: string, lockedReportId: string): Promise<boolean> {
        const res = await httpDelete(`/compliance-reports-sharing/${lockedReportId}`)
        return res.ok
    }

    getLockedReportUploadURL(reportId: string, lockedReportId: string): string {
        return `${API_URL}/compliance-reports/${reportId}/locked-reports/${lockedReportId}/submit-file`
    }

    async getLockedReportUploadFinished(
        reportId: string,
        lockedReportId: string
    ): Promise<boolean> {
        type IResultType = {
            item_exists: boolean
        }
        const data = await httpGet<IResultType>(
            `/compliance-reports-sharing/${lockedReportId}/is-finished-loading`
        )
        return data.data?.item_exists || false
    }

    async getReportData(reportId: string): Promise<IReportDataRes> {
        const reportDataRes = await httpGet<IReportDataRes>(`/compliance-reports/${reportId}`)
        return reportDataRes.data
    }

    async refreshTaxYear(taxYearId: string): Promise<void> {
        await httpPut(`/ll-tax-years/${taxYearId}/refresh`)
    }

    async lockTaxYear(taxYearId: string): Promise<void> {
        await httpPost(`/ll-tax-years/${taxYearId}/lock`)
    }

    async unlockTaxYear(taxYearId: string): Promise<void> {
        await httpDelete(`/ll-tax-years/${taxYearId}/lock`)
    }

    async shareTaxYear(taxYearId: string): Promise<ISharingLinkRes> {
        const res = await httpPost<ISharingLinkRes>(`/ll-tax-years/${taxYearId}/sharing-links`)
        return res.data
    }

    async getDocumentation(LLUserId: string, date: Date | number | string): Promise<any> {
        const res = await httpGet<IFileListRes>(
            `/documentation/date/${format(toDate(new Date(date)), 'yyyy-MM-dd')}`
        )
        return res.data
    }

    async deleteDocumentation(LLUserId: string, uidToDelete: string): Promise<void> {
        await httpDelete('/documentation/', {
            documentation_id: uidToDelete,
        })
    }

    getUploadDocPath(LLUserId: string, date: Date | number | string): string {
        return `${API_URL}/documentation/date/${format(toDate(new Date(date)), 'yyyy-MM-dd')}`
    }

    async changeIncludeReportSwitch(LLUserId: string, fileUid: string): Promise<changeResponse> {
        const res = await httpPut<changeResponse>(
            `/documentation/file/${fileUid}/change-include-report`
        )
        return res.data
    }

    async getDocDateSum(LLUserId: string, date: Date | number | string): Promise<DocDateSum> {
        const dateStr = format(toDate(new Date(date)), 'yyyy-MM-dd')
        const res = await httpGet<DocDateSum>(`/documentation/date/${dateStr}/sum`)
        return res.data
    }

    async changeDocIncludeReportSwitch(
        LLUserId: string,
        reportId: string
    ): Promise<changeResponse> {
        const res = await httpPut<changeResponse>(
            `/compliance-reports/${reportId}/change-doc-included`,
            {}
        )
        return res.data
    }

    async changeNotesIncludeReportSwitch(
        LLUserId: string,
        reportId: string
    ): Promise<changeResponse> {
        const res = await httpPut<changeResponse>(
            `/compliance-reports/${reportId}/change-notes-included`,
            {}
        )
        return res.data
    }

    async changeLocationsIncludeReportSwitch(
        LLUserId: string,
        reportId: string
    ): Promise<changeResponse> {
        const res = await httpPut<changeResponse>(
            `/compliance-reports/${reportId}/change-locations-included`,
            {}
        )
        return res.data
    }

    async changeRosterIncludeReportSwitch(
        LLUserId: string,
        reportId: string
    ): Promise<changeResponse> {
        const res = await httpPut<changeResponse>(
            `/compliance-reports/${reportId}/change-roster-included`,
            {}
        )
        return res.data
    }

    async insertVacationDay(LLUserId: string, date: Date | number | string): Promise<void> {
        await httpPost<any>(`/vacations/date/${date}`, {})
    }

    async deleteVacationDay(LLUserId: string, date: Date | number | string): Promise<void> {
        await httpDelete<any>(`/vacations/date/${date}`, {})
    }

    async updateUserNote(
        LLUserId: string,
        date: Date | number | string,
        body: Object
    ): Promise<void> {
        await httpPut<any>(`/user-notes/${date}`, body)
    }

    async deleteUserNote(LLUserId: string, date: Date | number | string): Promise<void> {
        await httpDelete<any>(`/user-notes/${date}`)
    }

    async getPlacePredictions(
        LLUserId: string,
        sessionToken: string,
        request: { input: string },
        callback: (results?: PlaceType[]) => void
    ): Promise<void> {
        const res = await httpGet<{ predictions: PlaceType[] }>(
            `/user-locations/predictions?input_text=${request.input}&session_token=${sessionToken}`
        )
        callback(res?.data?.predictions)
    }

    async addUserLocation(
        LLUserId: string,
        date: Date | number | string,
        body: IAddPlaceAPI
    ): Promise<void> {
        await httpPost<any>(
            `/user-locations/${format(toDate(new Date(date)), 'yyyy-MM-dd')}/locations`,
            body
        )
    }

    async getUserLocationForDate(
        LLUserId: string,
        date: Date | number | string
    ): Promise<userLocation[]> {
        const res = await httpGet<{ locations: userLocation[] }>(
            `/user-locations/${format(toDate(new Date(date)), 'yyyy-MM-dd')}`
        )
        return res.data.locations
    }

    async deleteUserLocationForDate(
        LLUserId: string,
        date: Date | number | string,
        placeId: string
    ): Promise<void> {
        await httpDelete(
            `/user-locations/${format(toDate(new Date(date)), 'yyyy-MM-dd')}/locations/${placeId}`
        )
    }

    async confirmUserLocation(
        LLUserId: string,
        date: Date | number | string,
        placeId: string
    ): Promise<void> {
        await httpPost(
            `/user-locations/${format(toDate(new Date(date)), 'yyyy-MM-dd')}/locations/${placeId}/confirm`
        )
    }
}

export class RevisorFunctions implements APIFunctions {
    APIType: APIFunctionTypes

    constructor() {
        this.APIType = APIFunctionTypes.RevisorUser
    }

    getStoredAuthToken(): string {
        return getStoredAuthTokenRevisor()
    }

    getAuthHeader(): HeadersInit {
        return getAuthHeaderRevisor()
    }

    async getLLPeriod(periodId: string): Promise<ISinglePeriodCacheRes> {
        const res = await httpGetRevisor<ISinglePeriodCacheRes>(`/advisors/ll-periods/${periodId}`)
        return res.data
    }

    async getLLPeriodSmall(periodId: string): Promise<ISinglePeriodRes> {
        const res = await httpGetRevisor<ISinglePeriodRes>(`/advisors/ll-periods/${periodId}/small`)
        return res.data
    }

    async fetchTaxYearData(taxYear: ITaxYear): Promise<ITaxYearDataRes> {
        const fetchUrl = `/advisors/ll-tax-years/${taxYear.id}`
        const res = await httpGetRevisor<ITaxYearDataRes>(fetchUrl)
        return res.data
    }

    async getTaxYears(): Promise<ITaxYearRes> {
        const taxYearsData = await httpGetRevisor<ITaxYearRes>('/advisors/tax-years')
        return taxYearsData.data
    }

    async lockReport(reportId: string): Promise<ILockRes> {
        const lockRes = await httpPostRevisor<ILockRes>(
            `/advisors/compliance-reports/${reportId}/locked-reports`
        )
        return lockRes.data
    }

    async removeLockFromReport(reportId: string, lockedReportId: string): Promise<boolean> {
        const res = await httpDeleteRevisor(
            `/advisors/compliance-reports/${reportId}/locked-reports/${lockedReportId}`
        )
        return res.ok
    }

    getLockedReportUploadURL(reportId: string, lockedReportId: string): string {
        return `${API_URL}/advisors/compliance-reports/${reportId}/locked-reports/${lockedReportId}/submit-file`
    }

    async getLockedReportUploadFinished(
        reportId: string,
        lockedReportId: string
    ): Promise<boolean> {
        type IResultType = {
            item_exists: boolean
        }
        const data = await httpGetRevisor<IResultType>(
            `/advisors/compliance-reports/${reportId}/locked-reports/${lockedReportId}/is-finished-loading`
        )
        return data.data?.item_exists || false
    }

    async getReportData(reportId: string): Promise<IReportDataRes> {
        const reportDataRes = await httpGetRevisor<IReportDataRes>(
            `/advisors/compliance-reports/${reportId}`
        )
        return reportDataRes.data
    }

    async refreshTaxYear(taxYearId: string): Promise<void> {
        await httpPutRevisor(`/advisors/ll-tax-years/${taxYearId}/refresh`, {})
    }

    async lockTaxYear(taxYearId: string): Promise<void> {
        await httpPostRevisor(`/advisors/ll-tax-years/${taxYearId}/lock`, {})
    }

    async unlockTaxYear(taxYearId: string): Promise<void> {
        await httpDeleteRevisor(`/advisors/ll-tax-years/${taxYearId}/lock`, {})
    }

    async shareTaxYear(taxYearId: string): Promise<ISharingLinkRes> {
        const res = await httpPostRevisor<ISharingLinkRes>(
            `/advisors/ll-tax-years/${taxYearId}/sharing-links`,
            {}
        )
        return res.data
    }

    async getDocumentation(LLUserId: string, date: Date | number | string): Promise<any> {
        const res = await httpGetRevisor<IFileListRes>(
            `/advisors/documentation/${LLUserId}/date/${format(
                toDate(new Date(date)),
                'yyyy-MM-dd'
            )}`
        )
        return res.data
    }

    async deleteDocumentation(LLUserId: string, uidToDelete: string): Promise<void> {
        await httpDeleteRevisor(`/advisors/documentation/${LLUserId}`, {
            documentation_id: uidToDelete,
        })
    }

    getUploadDocPath(LLUserId: string, date: Date | number | string): string {
        return `${API_URL}/advisors/documentation/${LLUserId}/date/${format(
            toDate(new Date(date)),
            'yyyy-MM-dd'
        )}`
    }

    async changeIncludeReportSwitch(LLUserId: string, fileUid: string): Promise<changeResponse> {
        const res = await httpPutRevisor<changeResponse>(
            `/advisors/documentation/${LLUserId}/file/${fileUid}/change-include-report`,
            {}
        )
        return res.data
    }

    async getDocDateSum(LLUserId: string, date: Date | number | string): Promise<DocDateSum> {
        const dateStr = format(toDate(new Date(date)), 'yyyy-MM-dd')
        const res = await httpGetRevisor<DocDateSum>(
            `/advisors/documentation/${LLUserId}/date/${dateStr}/sum`
        )
        return res.data
    }

    async getLLUserData(LLUserId: string): Promise<User> {
        const res = await httpGetRevisor<Session>(`/advisors/ll-user/${LLUserId}`)
        return res.data.user
    }

    async changeDocIncludeReportSwitch(
        LLUserId: string,
        reportId: string
    ): Promise<changeResponse> {
        const res = await httpPutRevisor<changeResponse>(
            `/advisors/${LLUserId}/reports/${reportId}/change-doc-included`,
            {}
        )
        return res.data
    }

    async changeNotesIncludeReportSwitch(
        LLUserId: string,
        reportId: string
    ): Promise<changeResponse> {
        const res = await httpPutRevisor<changeResponse>(
            `/advisors/${LLUserId}/reports/${reportId}/change-notes-included`,
            {}
        )
        return res.data
    }

    async changeLocationsIncludeReportSwitch(
        LLUserId: string,
        reportId: string
    ): Promise<changeResponse> {
        const res = await httpPutRevisor<changeResponse>(
            `/advisors/${LLUserId}/reports/${reportId}/change-locations-included`,
            {}
        )
        return res.data
    }

    async changeRosterIncludeReportSwitch(
        LLUserId: string,
        reportId: string
    ): Promise<changeResponse> {
        const res = await httpPutRevisor<changeResponse>(
            `/advisors/${LLUserId}/reports/${reportId}/change-roster-included`,
            {}
        )
        return res.data
    }

    async insertVacationDay(LLUserId: string, date: Date | number | string): Promise<void> {
        await httpPostRevisor<any>(`/advisors/vacations/${LLUserId}/date/${date}`, {})
    }

    async deleteVacationDay(LLUserId: string, date: Date | number | string): Promise<void> {
        await httpDeleteRevisor<any>(`/advisors/vacations/${LLUserId}/date/${date}`, {})
    }

    async updateUserNote(
        LLUserId: string,
        date: Date | number | string,
        body: Object
    ): Promise<void> {
        await httpPutRevisor<any>(`/advisors/user-notes/${LLUserId}/date/${date}`, body)
    }

    async deleteUserNote(LLUserId: string, date: Date | number | string): Promise<void> {
        await httpDeleteRevisor<any>(`/advisors/user-notes/${LLUserId}/date/${date}`)
    }

    async getPlacePredictions(
        LLUserId: string,
        sessionToken: string,
        request: { input: string },
        callback: (results?: PlaceType[]) => void
    ): Promise<void> {
        const res = await httpGetRevisor<{ predictions: PlaceType[] }>(
            `/advisors/user-locations/${LLUserId}/predictions?input_text=${request.input}&session_token=${sessionToken}`
        )
        callback(res?.data?.predictions)
    }

    async addUserLocation(
        LLUserId: string,
        date: Date | number | string,
        body: IAddPlaceAPI
    ): Promise<void> {
        await httpPostRevisor<any>(
            `/advisors/user-locations/${LLUserId}/date/${format(
                toDate(new Date(date)),
                'yyyy-MM-dd'
            )}/locations`,
            body
        )
    }

    async getUserLocationForDate(
        LLUserId: string,
        date: Date | number | string
    ): Promise<userLocation[]> {
        const res = await httpGetRevisor<{ locations: userLocation[] }>(
            `/advisors/user-locations/${LLUserId}/date/${format(
                toDate(new Date(date)),
                'yyyy-MM-dd'
            )}`
        )
        return res.data.locations
    }

    async deleteUserLocationForDate(
        LLUserId: string,
        date: Date | number | string,
        placeId: string
    ): Promise<void> {
        await httpDeleteRevisor(
            `/advisors/user-locations/${LLUserId}/date/${format(
                toDate(new Date(date)),
                'yyyy-MM-dd'
            )}/locations/${placeId}`
        )
    }

    async confirmUserLocation(
        LLUserId: string,
        date: Date | number | string,
        placeId: string
    ): Promise<void> {
        await httpPostRevisor(
            `/advisors/user-locations/${LLUserId}/date/${format(
                toDate(new Date(date)),
                'yyyy-MM-dd'
            )}/locations/${placeId}/confirm`
        )
    }
}

export function getUserTypeFunctions(contentType: ComplianceReportsTypes): APIFunctions {
    switch (contentType) {
        case ComplianceReportsTypes.LLUser:
            return new LLUserFunctions()
        case ComplianceReportsTypes.Revisor:
            return new RevisorFunctions()
    }
}
