import { Button, Form, Input, message, Modal } from 'antd'
import { MailOutlined } from '@ant-design/icons'
import React, { useState } from 'react'
import { Session, SessionRevisor } from '../../types/session'

export interface IEmailModal {
    visible: boolean
    setVisible: (input: boolean) => void
    session: Session | SessionRevisor
    submitRequest: (i: any) => Promise<any>
}

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
}
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
}

export function ChangeEmailModal({ visible, setVisible, session, submitRequest }: IEmailModal) {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)

    const close = () => {
        setVisible(false)
    }
    const submit = async (values: any) => {
        setLoading(true)
        try {
            await submitRequest(values)
            message.success('Changed E-mai!')
            Modal.success({
                title: 'E-mail Sent for verification!',
                content:
                    'We have sent an E-mail to the provided address,' +
                    ' your change of E-mail address will take effect when you' +
                    ' have verified that you own the E-mail address. Please ' +
                    'go to your email inbox and click the link in the mail.',
            })
        } catch (error) {
            // message.error('something went wrong');
            message.error(error.data.detail)
        }
        setLoading(false)
        close()
    }

    return (
        <Modal visible={visible} onCancel={close} onOk={close} destroyOnClose footer={[]}>
            <div className="change-password-modal">
                <h3 className="change-email-text" style={{ textDecoration: 'underline' }}>
                    Change your E-mail
                </h3>
                <p className="change-email-text">Current: {session?.user?.email ?? 'No E-mail'}</p>
                <Form
                    {...formItemLayout}
                    form={form}
                    name="changeEmail"
                    onFinish={submit}
                    scrollToFirstError
                >
                    <Form.Item
                        hasFeedback
                        name="email"
                        rules={[
                            { required: true, message: 'Please enter your email!' },
                            { type: 'email', message: 'Not a valid email' },
                        ]}
                        label="New E-mail"
                        className="signin-input"
                    >
                        <Input
                            prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                            placeholder="Email"
                            className="signin-input"
                        />
                    </Form.Item>
                    <Form.Item {...tailFormItemLayout}>
                        <Button loading={loading} type="primary" htmlType="submit">
                            Change
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    )
}
