import React from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import parse from 'autosuggest-highlight/parse'
import throttle from 'lodash/throttle'
import { PlaceType } from '../../lib/interfaces'
import { APIFunctions } from '../../lib/apiFunctions'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'

const useStyles = makeStyles((theme) => ({
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
    },
    googleLogo: {
        marginLeft: 'calc(100% - 144px)',
        width: '144px',
        height: '18px',
    },
}))

export default function GoogleAutoComplete({
    inputValue,
    setInputValue,
    searchResult,
    setSearchResult,
    loading,
    setLoading,
    apiFunctions,
    sessionToken,
    userId,
}: {
    inputValue
    setInputValue
    searchResult: PlaceType | null
    setSearchResult: any
    loading: boolean
    setLoading: any
    apiFunctions: APIFunctions
    sessionToken: string
    userId: string
}) {
    const classes = useStyles()
    const [options, setOptions] = React.useState<PlaceType[]>([])

    const data = useStaticQuery(graphql`
        query {
            s1: file(relativePath: { eq: "powered_by_google_on_white_hdpi.png" }) {
                childImageSharp {
                    fluid(maxWidth: 144) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            }
        }
    `)

    const fetch = React.useMemo(
        () =>
            throttle(
                (
                    request: { input: string; sessionTokenInput: string },
                    callback: (results?: PlaceType[]) => void
                ) => {
                    if (request.input.length < 3) return
                    apiFunctions.getPlacePredictions(
                        userId,
                        request.sessionTokenInput,
                        request,
                        callback
                    )
                },
                1500
            ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [apiFunctions, userId]
    )

    React.useEffect(() => {
        let active = true

        if (inputValue === '' || !sessionToken) {
            setOptions(searchResult ? [searchResult] : [])
            return undefined
        }

        fetch({ input: inputValue, sessionTokenInput: sessionToken }, (results?: PlaceType[]) => {
            if (active) {
                let newOptions = [] as PlaceType[]

                if (searchResult) {
                    newOptions = [searchResult]
                }

                if (results) {
                    newOptions = [...newOptions, ...results]
                }

                setOptions(newOptions)
            }
        })

        return () => {
            active = false
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchResult, inputValue, fetch])

    return (
        <>
            <Autocomplete
                id="locations-autocomplete"
                style={{ width: 300 }}
                getOptionLabel={(option) =>
                    typeof option === 'string' ? option : option.description
                }
                filterOptions={(x) => x}
                options={options}
                size="small"
                autoComplete
                includeInputInList
                filterSelectedOptions
                value={searchResult}
                onChange={(event: any, newValue: PlaceType | null | any) => {
                    setOptions(newValue ? [newValue, ...options] : options)
                    setSearchResult(newValue)
                }}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue)
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Add locations abroad"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? (
                                        <CircularProgress color="inherit" size={20} />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                )}
                renderOption={(option) => {
                    const matches = option.main_text_matched_substrings
                    const parts = parse(
                        option.main_text,
                        matches.map((match: any) => [match.offset, match.offset + match.length])
                    )

                    return (
                        <Grid container alignItems="center">
                            <Grid item>
                                <LocationOnIcon className={classes.icon} />
                            </Grid>
                            <Grid item xs>
                                {parts.map((part, index) => (
                                    <span
                                        key={index}
                                        style={{ fontWeight: part.highlight ? 700 : 400 }}
                                    >
                                        {part.text}
                                    </span>
                                ))}
                                <Typography variant="body2" color="textSecondary">
                                    {option.secondary_text}
                                </Typography>
                            </Grid>
                        </Grid>
                    )
                }}
            />
            {/* theese dimension are found on google's website: https://developers.google.com/maps/documentation/places/web-service/policies */}
            {/* <img
                className={classes.googleLogo}
                width={'144px'}
                height={'18px'}
                src="/powered_by_google_on_white_hdpi.png"
            /> */}
            <Img
                title="Google logo"
                className={classes.googleLogo}
                fluid={data.s1.childImageSharp.fluid}
            />
        </>
    )
}
