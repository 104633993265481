import {
    docDate,
    IDateRange,
    IGraphData,
    IGraphDataInput,
    IGraphPoint,
    IGraphPointInput,
    IPeriodCache,
} from './interfaces'
import { isAfter, isSameDay, toDate, format, addDays, isBefore, startOfToday } from 'date-fns'
import { colorConverter, colorSchemaInput } from './userNoteColors'

function isBetween(dateRange: IDateRange, dateToCompare: number | Date) {
    return (
        dateRange.end !== null &&
        (isAfter(dateToCompare, dateRange.start) || isSameDay(dateToCompare, dateRange.start)) &&
        (isAfter(dateRange.end, dateToCompare) || isSameDay(dateToCompare, dateRange.end))
    )
}

export interface IGraphItem {
    date: string
    redDayCount: number
    blueDayCount?: number
    greenDayCount?: number
}

export function isInsidePeriod(dateRanges: IDateRange[], dateToCompare: Date | number) {
    return dateRanges.find((dateRange) => isBetween(dateRange, dateToCompare)) || false
}

export interface dataReceiver {
    dateRanges: IDateRange[]
}

export interface docDatesReceiver {
    docDates: (number | Date)[]
}

export interface userNotesReceiver {
    target_date: number | Date
    note_color: string
    note_content: string
    include_report: boolean
    include_graph: boolean
    created_on: number | Date
    modified_on: number | Date
}

export const dateFormat = 'MMM/dd/yyyy'

export const mapUserNotes = (userNotes: userNotesReceiver[]) => {
    return userNotes.map((n) => {
        return {
            target_date: new Date(n.target_date),
            note_color: colorConverter[n.note_color],
            note_content: n.note_content,
            include_report: n.include_report,
            include_graph: n.include_graph,
            created_on: n.created_on,
            modified_on: n.modified_on,
        }
    })
}

export interface UserLocationDate {
    target_date: string
    location_count: number
}

export interface IRosterDate {
    categories: string
    roster_date: Date | number
}

export interface IRosterReceiver {
    roster_dates: IRosterDate[]
}

export const mapRosterDates = (rosterData: IRosterReceiver): IRosterDate[] => {
    if (!rosterData.roster_dates) {
        return []
    }
    return rosterData.roster_dates.map((d) => {
        return {
            categories: d.categories,
            roster_date: new Date(d.roster_date),
        }
    })
}

export function mapPeriodData(data: dataReceiver | undefined): IDateRange[] {
    const drs = data?.dateRanges.map((dateRange: IDateRange) => {
        const startDate = new Date(dateRange.start)
        startDate.setHours(0)
        const endDate = new Date(dateRange.end)
        endDate.setHours(0)
        return {
            start: startDate,
            end: endDate,
        }
    })
    return drs || []
}

export function mapEXPeriodData(data: IPeriodCache[] | undefined): IPeriodCache[] {
    const drs = data
        ?.map((dateRange: IPeriodCache) => {
            const startDate = (dateRange.start && new Date(dateRange.start)) || null
            if (startDate) {
                startDate.setHours(0)
            }
            const endDate = (dateRange.end && new Date(dateRange.end)) || null
            if (endDate) {
                endDate.setHours(0)
            }
            return {
                id: dateRange.id,
                start: startDate,
                end: endDate,
                max_day_count: dateRange.max_day_count,
                period_title: dateRange.period_title,
                period_color: dateRange.period_color,
            }
        })
        .sort((a, b) => (a.start?.valueOf() || 0) - (b.start?.valueOf() || 0))
    return drs || []
}

export function mapGraphPeriodData(data: IGraphDataInput): IGraphData {
    const drs = data.dataPoints.map((graphItem: IGraphPointInput) => {
        const graphDate = new Date(graphItem.date)
        if (graphDate) {
            graphDate.setHours(0)
        }
        let dp: IGraphPoint = {
            date: format(toDate(graphDate), dateFormat),
            trueDate: graphDate,
            wasindk: graphItem.wasindk,
            generel_daycount: graphItem.generel_daycount,
            ex_period_daycount: graphItem.ex_period_daycount,
        }
        if (!graphItem.user_note_color) return dp
        switch (graphItem.user_note_color) {
            case colorSchemaInput.red:
                dp.user_note_red = 1
                break
            case colorSchemaInput.yellow:
                dp.user_note_yellow = 1
                break
            case colorSchemaInput.purple:
                dp.user_note_purple = 1
                break
        }
        return dp
    })
    return { dataPoints: drs || [] }
}

export const layoutDictKeyFormat = 'yyyy-MM-dd'

export const modifiersClassNames = {
    highlight: '-highlight',
    startDate: '-startdate',
    endDate: '-enddate',
    middleDate: '-middledate',
    docDate: '-docdate',
    docDateExcluded: '-excludeddocdate',
    noteDate: '-notedate',
    nonperiod: '-nonperiod',
    gray: '-gray',
    yellow: '-yellow',
    green: '-green',
    usernoteRed: '-usernotered',
    usernoteYellow: '-usernoteyellow',
    usernotePurple: '-usernotepurple',
    rosterDate: '-roster',
    userLocations: '-userlocations',
}

export function getCalendarLayoutDates(
    dateRanges: IDateRange[],
    docDates: docDate[],
    userNotes: userNotesReceiver[],
    rosterDates: IRosterDate[],
    llPeriods: IPeriodCache[],
    userLocationDates: UserLocationDate[]
): { [id: number]: string } {
    let resDict: { [id: number]: string } = {}
    const getKey = (date: Date | number | string) => {
        return format(toDate(new Date(date)), layoutDictKeyFormat)
    }
    const addModifier = (date: Date | number | string, modifier: string): void => {
        resDict[getKey(date)] =
            (resDict[getKey(date)] ? resDict[getKey(date)] + ' ' : '') + modifier
    }
    const setPeriodColor = (date: Date | number): void => {
        let llp: IPeriodCache
        for (var i = 0; i < llPeriods.length; i++) {
            if (llPeriods[i].start && isAfter(llPeriods[i].start, toDate(date))) continue
            if (llPeriods[i].end && isAfter(date, llPeriods[i].end)) continue
            llp = llPeriods[i]
            break
        }
        if (llp) addModifier(date, modifiersClassNames[llp.period_color])
    }

    dateRanges.forEach((dr) => {
        addModifier(dr.start, modifiersClassNames.startDate)
        setPeriodColor(dr.start)
        if (dr.end) addModifier(dr.end, modifiersClassNames.endDate)
        setPeriodColor(dr.end)
        let currentDate = addDays(dr.start, 1)
        while (isBefore(toDate(currentDate), toDate(dr.end))) {
            addModifier(currentDate, modifiersClassNames.middleDate)
            setPeriodColor(currentDate)
            currentDate = addDays(currentDate, 1)
        }
    })
    docDates.forEach((dd) => {
        addModifier(new Date(dd.documentation_date), modifiersClassNames.docDate)
        if (dd.date_is_included_in_report) {
        } else {
            addModifier(dd.documentation_date, modifiersClassNames.docDateExcluded)
        }
    })
    userNotes.forEach((un) => {
        addModifier(un.target_date, modifiersClassNames[un.note_color])
    })
    rosterDates.forEach((rd) => {
        addModifier(rd.roster_date, modifiersClassNames.rosterDate)
    })
    userLocationDates.forEach((uld) => {
        addModifier(uld.target_date, modifiersClassNames.userLocations)
    })
    addModifier(startOfToday(), modifiersClassNames.highlight)
    return resDict
}

export function alterGraphDate(
    graphData: IGraphData,
    targetDate,
    alterFun: (dp: IGraphPoint) => IGraphPoint
): IGraphData {
    if (!alterFun) {
        return {
            dataPoints: graphData.dataPoints.filter(
                (dp) => !isSameDay(toDate(new Date(dp.date)), toDate(new Date(targetDate)))
            ),
        }
    }
    return {
        dataPoints: graphData.dataPoints.map((dp) => {
            if (dp.date === format(toDate(new Date(targetDate)), dateFormat)) {
                return alterFun(dp)
            }
            return dp
        }),
    }
}
