export const colorSchema = ['usernoteRed', 'usernoteYellow', 'usernotePurple']

export enum colorSchemaInput {
    red = 'red',
    yellow = 'yellow',
    purple = 'purple',
}

export const colorConverter: Record<string, string> = {
    purple: 'usernotePurple',
    yellow: 'usernoteYellow',
    red: 'usernoteRed',
}

export const colorConverterInv: Record<string, string> = {
    usernotePurple: 'purple',
    usernoteYellow: 'yellow',
    usernoteRed: 'red',
}

export const colorConverterCSS: Record<string, string> = {
    usernotePurple: 'usernotepurple',
    usernoteYellow: 'usernoteyellow',
    usernoteRed: 'usernotered',
}
