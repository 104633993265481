import { message, Switch } from 'antd'
import React, { useEffect, useState } from 'react'
import { APIFunctions } from '../../lib/apiFunctions'
import { IFileList } from '../../lib/interfaces'

export default function DocFileItem(
    originNode,
    file: IFileList,
    currFileList: IFileList[],
    reportChange,
    dateClicked,
    parentVisible,
    apiFunctions: APIFunctions,
    userId: string,
    setFileList: (i: IFileList[]) => void
) {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isIncluded, setIsIncluded] = useState<boolean>(
        file?.include_report !== undefined && file?.include_report !== null
            ? file?.include_report
            : true
    )

    useEffect(()=>{
        setIsIncluded(
            file?.include_report !== undefined && file?.include_report !== null
                ? file?.include_report
                : true
        )
    }, [file, parentVisible])

    async function changeIncludeDocInReport() {
        setIsLoading(true)
        try {
            const data = await apiFunctions.changeIncludeReportSwitch(userId, file.uid)
            setFileList(currFileList.map(f => {
                if (f.uid === file.uid) {
                    return {...f, include_report: data.is_included}
                }
                return f
            }))
            setIsIncluded(data.is_included)
            if (reportChange)
                reportChange({
                    targetDate: dateClicked,
                    action: data.is_included ? 'include' : 'exclude',
                    fileId: file.uid,
                    isIncluded: data.is_included,
                    newTotal: currFileList.length,
                    newActive:
                        currFileList.filter((f) => f.include_report).length + Number(data.is_included)
                            ? 1
                            : -1,
                })
        } catch (ex) {
            message.error('Could not switch file')
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div
            style={{
                height: '80%',
            }}
        >
            {originNode}
            <Switch
                loading={isLoading}
                onChange={changeIncludeDocInReport}
                checked={isIncluded}
                checkedChildren="Included"
                unCheckedChildren="Excluded"
            />
        </div>
    )
}
