export enum APIFunctionTypes {
    LLUser = 'll-user',
    RevisorUser = 'revisor'
}

export enum ComplianceReportsTypes {
    LLUser = 'll-user',
    Revisor = 'revisor'
}

export enum editType {
    Start = 'start',
    End = 'end',
}

export const accessOptions = [
    { label: 'View', value: 'view' },
    { label: 'View & download', value: 'view share' },
    { label: 'View & edit', value: 'view edit' },
    { label: 'View, edit & download', value: 'view edit share' },
]
